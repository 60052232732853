<template>
    <div
        v-if="isAnnouncementVisible"
        id="announcement-bar"
        class="relative mb-[10px] flex items-center justify-between bg-announcementgray text-black sm:mb-[0] md:mx-[10px] lg:mx-[40px] xl:mx-[58px]"
        :style="{ backgroundColor: background_color }">
        <div class="w-full text-center leading-10" v-html="alertContent"></div>
        <button @click="closeAnnouncement" class="h-[40px] px-2 py-1">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                class="h-5 w-5 text-white">
                <path
                    fill-rule="evenodd"
                    d="M5.293 5.293a1 1 0 0 1 1.414 0L10 8.586l3.293-3.293a1 1 0 0 1 1.414 1.414L11.414 10l3.293 3.293a1 1 0 1 1-1.414 1.414L10 11.414l-3.293 3.293a1 1 0 0 1-1.414-1.414L8.586 10 5.293 6.707a1 1 0 0 1 0-1.414z"
                    clip-rule="evenodd" />
            </svg>
        </button>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    const isAnnouncementVisible = ref(true)

    const closeAnnouncement = () => {
        isAnnouncementVisible.value = false
        setSessionCookie('announcementClosed', 'true')
    }

    const openAnnouncement = () => {
        isAnnouncementVisible.value = true
    }

    const props = defineProps(['alertContent', 'alertEnabled', 'background_color'])

    onMounted(() => {
        const announcementClosed = getSessionCookie('announcementClosed')
        if (announcementClosed === 'true') {
            isAnnouncementVisible.value = false
        }
    })
    function setSessionCookie(name, value) {
        document.cookie = `${name}=${value}; path=/`
    }

    // Function to get the value of a session cookie
    function getSessionCookie(name) {
        const cookies = document.cookie.split(';')
        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.trim().split('=')
            if (cookieName === name) {
                return cookieValue
            }
        }
        return null
    }
</script>

<style scoped>
    #announcement-bar {
        /*animation: flashYellow 1s linear infinite;*/
    }

    @keyframes flashYellow {
        0% {
            background-color: #65e8a4;
        }
        50% {
            background-color: #65e8a4;
        }
        100% {
            background-color: #65e8a4;
        }
    }

    button {
        transition: opacity 0.3s;
    }

    button:hover {
        opacity: 0.7;
    }
    @media (max-width: 640px) {
        #announcement-bar {
            width: 100%;
        }
    }
</style>
